import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.apiUrl+'/auth/google';


  private _isLoggedIn = new BehaviorSubject<boolean>(false);;
  private _userProfile: any = null;

  constructor(private http: HttpClient){
    const token = localStorage.getItem('token');
    if (token) {
      this._isLoggedIn.next(true);
      // Optionally, reload the user profile if needed
      // this.loadUserProfile(token);
    }
  }

  isLoggedIn$ = this._isLoggedIn.asObservable();
  get isLoggedIn(): boolean {
    return this._isLoggedIn.value;
  }

  get userProfile(): any {
    return this._userProfile;
  }

  logout() {
    this._isLoggedIn.next(false);
    this._userProfile = null;
    localStorage.removeItem("token")
  }
  loginWithGoogle(token: string): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post(this.apiUrl, { token }, {headers}).pipe(
      tap((response: any) => {
        // Aquí puedes guardar el perfil del usuario en AuthService
        this._isLoggedIn.next(true);
        this._userProfile = response.user; // Guarda el perfil de usuario recibido del backend
        localStorage.setItem("token", response.appToken)
      })
    );
  }
  getToken(): string | null {
    return localStorage.getItem('token');
  }
}
